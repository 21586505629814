<template>
  <div>
    <Steps class="p-t-10" :current="current">
      <Step v-for="(step,i) in steps" :key="i" :title="step.title" :content="step.desc"></Step>
    </Steps>
    <div style="margin:30px 15px;">
    <component ref="stepComponent" :is="component"   />

    </div>

  </div>
</template>

<script>
import basic from '@/components/investmentContract/editComponents/basic'
import price from '@/components/investmentContract/editComponents/price'
import other from '@/components/investmentContract/editComponents/OtherSetting'
import files from '@/components/investmentContract/editComponents/UploadFiles'
import commission from '@/components/contract/contractDetail/edit/Commission'
import detail from '@/components/investmentContract/editComponents/confirmContract'

// import { updateDraft } from '@/api/scp/contract'
import { getContractInfo } from '@/api/invest/contract'
// import { ParseDate } from '@/utils/dateFormat'

export default {
  components: {
    basic, price, other, commission, detail, files
  },
  data () {
    return {
      current: 0,
      component: ''
    }
  },
  methods: {
    initPageData () {
      getContractInfo({ contractId: this.id, hideFlag: 1 }).then(res => {
        this.$store.commit('set_contract_contractInfo', res)
      })
    }
  },
  watch: {
    currentStep: function () {
      this.component = this.currentStep
      this.current = this.steps.indexOf(this.steps.filter(x => x.name === this.currentStep)[0])
    },
    beginUpdate () {
      this.initPageData()
    }
  },
  created () {
    this.initPageData()
    this.component = this.currentStep
  },
  computed: {
    steps: function () {
      // const allowCommission = this.$store.getters.token.publisherSetting.companyPerformance ? 0 : 1
      const steps = [
        { title: '基础信息', desc: '配置合同基础信息', name: 'basic' },
        { title: '资产配置', desc: '配置招商内容、计费信息', name: 'price' },
        { title: '其他配置', desc: '配置付款方式等信息', name: 'other' },
        { title: '附件信息', desc: '如果需要，提交一些附件', name: 'files' },
        { title: '确认合同信息', desc: '确认合同全部信息，并提交合同', name: 'detail' }
      ]

      return steps
    },
    id () {
      return this.$store.state.investmentContract.id
    },
    beginUpdate () {
      return this.$store.state.investmentContract.beginUpdate
    },
    currentStep () {
      return this.$store.state.investmentContract.currentStep
    }

  }
}
</script>

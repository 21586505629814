<template>
  <div>

    <Row :gutter="8" class="mb-2">
      <i-col span="12">
        <!-- <div style="font-size: 14px">资产信息</div> -->
      </i-col>
        <i-col span="12">
          <!-- <a style="float: right; line-height: 30px" @click="handleRentSetting(-99)"
          >配置租金</a
        > -->
        <a style="float: right; line-height: 30px;padding-right:15px;" @click="handleChoiceSpace()"
          >配置招商内容</a
        >
        </i-col>
          <i-col span="24">
            <Table :columns="datacolumns"  stripe size="small" :data="contractResources"></Table>
          </i-col>
        </Row>

        <row class="p-t-10">
        <i-col span="24">
          <Button type="info" @click="handlePreviousStep">上一步</Button>
          <div class="right">
            <Button type="info" @click="handleNextStep">下一步</Button>
          </div>
        </i-col>
      </row>
      <rent-setting ref="rentSettingModal"></rent-setting>
      <choiceSpace
      ref="choiceSpaceModal"
      @on-success="initlistResourceAndFee"
      :chkSpaceArray="contractResources"
      :contractId="contractInfo.id"
    ></choiceSpace>
  </div>
</template>

<script>
// import { getPositions } from '@/api/product/resource'
// import { addDevice, updateDevice } from '@/api/product/deviceedit'
import RentSetting from './RentSetting.vue'
import { toMoney } from '@/utils/wnumb_own'
import { listObjectAndFee, deleteObjectBySpace } from '@/api/invest/contract'

import choiceSpace from './choiceSpace.vue'
/** new api */

export default {
  components: { RentSetting, choiceSpace },
  props: {},
  data () {
    return {
      contractResources: [],
      datacolumns: [
        // {
        //   title: '资产编码',
        //   dataIndex: 'resourceName',
        //   key: 'resourceName',
        //   fixed: true
        // },
        { title: '编码', dataIndex: 'objectCode', key: 'objectCode' },
        { title: '名称', dataIndex: 'objectName', key: 'objectName' },
        { title: '类型', dataIndex: 'resourceTypeName', key: 'resourceTypeName' },
        // { title: '备注', dataIndex: 'area', key: 'area' },
        {
          title: '计费周期',
          width: '200px',
          key: 'feeStartDate',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach(item => {
              resultStr.push(h(
                'p', {},
                item.startDate + '至' + item.endDate
              ))
            })

            return h('div', resultStr)
          }
        },
        {
          title: '计费类型',
          key: 'feeTypeName',
          width: '150',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach(item => {
              resultStr.push(h(
                'p', {},
                item.feeTypeName
              ))
            })

            return h('div', resultStr)
          }
        },
        {
          title: '计费数量',
          key: 'number',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach(item => {
              resultStr.push(h(
                'p', {},
                item.number
              ))
            })

            return h('div', resultStr)
          }
        },
        {
          title: '计费单价',
          key: 'feeSignPrice',
          width: '160px',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach(item => {
              resultStr.push(h(
                'p', {},
                item.signPrice + '元/' + (item.dateUnit === 1 ? '月' : '年')
              ))
            })

            return h('div', resultStr)
          }
        },
        {
          title: '计费总价',
          width: '160px',
          key: 'feeMonthPrice',
          align: 'center',
          render: (h, params) => {
            const resultStr = []
            params.row.feeList.forEach(item => {
              resultStr.push(h(
                'p', {},
                item.monthPrice + '元/' + (item.dateUnit === 1 ? '月' : '年')
              ))
            })

            return h('div', resultStr)
          }
        },

        // { title: '交付日期', dataIndex: 'deliveryDate', key: 'deliveryDate' },
        {
          title: '操作',
          align: 'center',
          width: 140,
          render: (h, params) => {
            const detailButton = h(
              'a',
              {
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.handleRentSetting(params.row.id)
                  }
                }
              },
              '调整计费策略'
            )
            const deleteButton = h(
              'a',
              {
                style: {
                  marginRight: '5px',
                  color: '#ef4f4f'
                },
                on: {
                  click: () => {
                    this.handleDeleteSpace(params.row.objectId)
                  }
                }
              },
              '删除'
            )

            return h('div', [detailButton, deleteButton])
          }
        }
      ]
    }
  },
  mounted () {},
  created () {
    this.initlistResourceAndFee()
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    initlistResourceAndFee () {
      const that = this
      listObjectAndFee({ contractId: that.contractInfo.id }).then((res) => {
        that.contractResources = res
      })
    },
    handleRentSetting (id) {
      this.$refs.rentSettingModal.showModal(this.contractResources, id)
    },
    handlePreviousStep () {
      this.$store.commit('set_contract_currentStep', 'basic')
      this.$store.commit('set_contract_update', new Date())
    },
    handleNextStep () {
      this.$store.commit('set_contract_currentStep', 'other')
      this.$store.commit('set_contract_update', new Date())
    },

    handleDeleteSpace (investSpaceId) {
      this.$Modal.confirm({
        title: '操作提示',
        content: '请问是否将招商内容从当前合同中移除？',
        onOk: () => {
          deleteObjectBySpace({ spaceId: investSpaceId, contractId: this.contractInfo.id }).then(res => {
            this.initlistResourceAndFee()
          })
        }
      })
    },

    // 租赁空间
    handleChoiceSpace () {
      this.$refs.choiceSpaceModal.showModal()
    }
  },
  watch: {
    beginUpdate () {
      this.initlistResourceAndFee()
    }
  },
  computed: {
    contractInfo () {
      return this.$store.state.investmentContract.contractInfo
    },
    beginUpdate () {
      return this.$store.state.investmentContract.beginUpdate
    }
  }
}
</script>

<style>
 .textDecoration{
  text-decoration: underline;
  color: #ed4014 !important;
  padding: 0px 5px;
 }
</style>
<template>
  <div>
    <Row>
      <i-col span="24">
        <div class="mb-2">
          <strong style="line-height: 45px">不计费区间</strong>
          <a class="text-blue-500" @click="visible = true">添加</a>
        </div>
        <Row :gutter="8" class="mb-2">
          <i-col span="24">
            <div v-if="!freePeriodArray.length">本合同未约定不计费区间</div>
            <div v-else>本合同约定不计费区间如下：</div>
            <p v-for="(item, index) in freePeriodArray" :key="index">
              {{ index + 1 }}.
              <label v-if="item.type === 1" style="line-height: 35px;">
                <span class="textDecoration"
                  >{{item.coNames}}</span
                >，
                <span
                 class="textDecoration"
                  > {{ item.startDate }} 至 {{ item.endDate }}</span>
               ,不计算

               <span
                 class="textDecoration"
                  >{{
                  item.feeType === 1 ? "资产费用" : "运营管理费"
                }}</span>
              </label>
              <label v-if="item.type === 2" style="line-height: 35px;"
                >
                <span
                 class="textDecoration"
                  >{{item.coNames}}</span
                >，
                自资产交付日起 <span
                 class="textDecoration"
                  >
                    {{ item.duration }}</span>个月，不计算

                    <span
                 class="textDecoration"
                  >
                  {{
                  item.feeType === 1 ? "资产费用" : "运营管理费"
                }}</span></label
              >

              <a class="text-blue-500" @click="deletefreePeriod(item.id)">
                移除
              </a>
            </p>
          </i-col>
        </Row>
      </i-col>
      <i-col span="24" style="border-top: 1px dotted #ccc">
        <div class="mb-2">
          <strong style="line-height: 45px">其他费用&结算方式</strong>
          <a class="text-blue-500" @click="showPaymentInfoModal()">编辑</a>
        </div>
        <Row :gutter="8" class="mb-2">
          <i-col span="24">
            <p style="line-height: 35px;">
              1.本合同⽣效之⽇，⼄⽅向甲⽅⽀付履约保证⾦<span class="textDecoration">{{
                contractInfo.depositAmount
              }}</span>元（⼈⺠币<span class="textDecoration">{{ formatUpperMoney(contractInfo.depositAmount) }}</span>）。
            </p>
            <p style="line-height: 35px;">
              2.乙方应同时按照每平方米<span class="textDecoration">{{
                contractInfo.propertyAmount
              }}</span>
              元/㎡·月（人民币<span class="textDecoration">{{
                formatUpperMoney(contractInfo.propertyAmount)
              }}</span>）向甲方另行支付运营管理费。
            </p>
            <p style="line-height: 35px;">
              3.⼄⽅每<span class="textDecoration">{{ contractInfo.paymentCycle }}</span>个⽉结算⼀次，⼄⽅应在每
              <span class="textDecoration">{{ contractInfo.paymentCycle }}</span> 个⽉期满
              <span class="textDecoration">{{ contractInfo.paymentAdvanceDay }}</span> ⽇前向甲方⽀付结算金额。
            </p>
            <p v-if="contractInfo.paymentStartDate" style="line-height: 35px;">
              4.首期费用（<span class="textDecoration">{{ contractInfo.paymentStartDate }}至{{
                contractInfo.paymentEndDate
              }}</span>）⼄⽅应在
              <span class="textDecoration">{{ contractInfo.paymentDueDate }} </span>一次性支付甲方。
            </p>
          </i-col>
        </Row>
      </i-col>
    </Row>
    <row class="p-t-10">
      <i-col span="24">
        <Button type="info" @click="handlePreviousStep">上一步</Button>
        <div class="right">
          <Button type="info" @click="handleNextStep">下一步</Button>
        </div>
      </i-col>
    </row>
    <Modal v-model="visible">
      <template #header>
        <p>添加不计费区间</p>
      </template>
      <Form
        ref="freePeriodForm"
        :model="freePeriodFormModel"
        :rules="freePeriodFormModel.type === 1 ? rules : rules1"
        :label-width="120"
      >
        <FormItem
          prop="type"
          label="费用类型"
          :rules="[{ required: true, message: '类型不能为空' }]"
        >
          <RadioGroup v-model="freePeriodFormModel.feeType">
            <Radio
              v-for="item in feeTypeArray"
              :key="item.id"
              :label="item.id"
              >{{ item.name }}</Radio
            >
          </RadioGroup>
        </FormItem>
        <FormItem
          prop="type"
          label="区间类型"
          :rules="[{ required: true, message: '类型不能为空' }]"
        >
          <RadioGroup v-model="freePeriodFormModel.type">
            <Radio v-for="item in typeArray" :key="item.id" :label="item.id">{{
              item.name
            }}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          prop="freeStartDate"
          label="开始日期"
          v-if="freePeriodFormModel.type === 1"
        >
          <DatePicker
            v-model="freePeriodFormModel.freeStartDate"
            type="date"
            placeholder="开始日期"
            class="w-full"
          />
        </FormItem>
        <FormItem
          prop="freeEndDate"
          label="结束日期"
          v-if="freePeriodFormModel.type === 1"
        >
          <DatePicker
            v-model="freePeriodFormModel.freeEndDate"
            type="date"
            placeholder="结束日期"
            class="w-full"
          />
        </FormItem>
        <FormItem
          prop="duration"
          label="周期"
          v-if="freePeriodFormModel.type === 2"
        >
          <Input
            type="text"
            v-model="freePeriodFormModel.duration"
            placeholder="周期"
          >
            <template #append>
              <span>个⽉</span>
            </template></Input
          >
        </FormItem>
        <FormItem prop="type" label="不计费范围">
          <!-- <CheckboxGroup
            size="small"
            class="m-b-10"
            v-model="freePeriodFormModel.assets"
          >
            <Checkbox :label="1"> 商业大厦</Checkbox>
            <Checkbox :label="2"> 自助机具合同</Checkbox>
          </CheckboxGroup> -->
          <CheckboxGroup v-model="coIdArray">
            <Checkbox v-for="item in objectList" :key="item.id" :label="item.id">{{
              item.objectName
            }}</Checkbox>
          </CheckboxGroup>
        </FormItem>
      </Form>
      <template #footer>
        <div class="text-right">
          <Button type="text" class="m-r-5" @click="visible = false"
            >关闭</Button
          >
          <Button type="primary" @click="handldSave">确定</Button>
        </div>
      </template>
    </Modal>
    <Modal v-model="editpaymentInfoModal">
      <template #header>
        <p>编辑其他费用&结算方式</p>
      </template>
      <Form
        ref="paymentInfoForm"
        :model="paymentInfoModel"
        :rules="paymentInfoRules"
        :label-width="150"
      >
        <FormItem prop="depositAmount" label="履约保证金">
          <Input
            type="text"
            v-model="paymentInfoModel.depositAmount"
            placeholder="履约保证金金额"
          >
            <template #append>
              <span>元</span>
            </template></Input
          >
        </FormItem>
        <FormItem prop="propertyAmount" label="运营管理费">
          <Input
            type="text"
            v-model="paymentInfoModel.propertyAmount"
            placeholder="运营管理费"
          >
            <template #append>
              <span>元/㎡·⽉</span>
            </template></Input
          >
        </FormItem>
        <FormItem prop="paymentCycle" label="结算周期">
          <Input
            type="text"
            v-model="paymentInfoModel.paymentCycle"
            placeholder="结算周期"
          >
            <template #append>
              <span>⽉</span>
            </template></Input
          >
        </FormItem>
        <FormItem prop="paymentAdvanceDay" label="提前付款天数">
          <Input
            type="text"
            v-model="paymentInfoModel.paymentAdvanceDay"
            placeholder="提前付款天数"
          >
            <template #append>
              <span>天</span>
            </template></Input
          >
        </FormItem>
        <FormItem prop="paymentStartDate" label="首付周期开始时间">
          <DatePicker
            v-model="paymentInfoModel.paymentStartDate"
            type="date"
            placeholder="首付周期开始时间"
            class="w-full"
          />
        </FormItem>
        <FormItem prop="paymentEndDate" label="首付周期结束时间">
          <DatePicker
            v-model="paymentInfoModel.paymentEndDate"
            type="date"
            placeholder="首付周期结束时间"
            class="w-full"
          />
        </FormItem>
        <FormItem prop="paymentEndDate" label="首付日期">
          <DatePicker
            v-model="paymentInfoModel.paymentDueDate"
            type="date"
            placeholder="首次付款日期"
            class="w-full"
          />
        </FormItem>
      </Form>
      <template #footer>
        <div class="text-right">
          <Button
            type="text"
            class="m-r-5"
            @click="editpaymentInfoModal = false"
            >关闭</Button
          >
          <Button type="primary" @click="handldPaymentInfoSave">确定</Button>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script >
import { parseUpperMoney } from '@/utils/index'
import { toMoney } from '@/utils/wnumb_own'
import { ParseDate } from '@/utils/dateFormat'

import {
  listFreePeriod,
  addFreePeriod,
  savePayAmount,
  savePayCycle,
  deleteFreePeriod
  , listObjectAndFee
} from '@/api/invest/contract'

export default {
  components: {},
  props: {},
  data () {
    return {
      freePeriodArray: [],
      visible: false,
      editpaymentInfoModal: false,
      typeArray: [
        { id: 1, name: '固定日期' },
        { id: 2, name: '固定周期' }
      ],
      feeTypeArray: [
        { id: 1, name: '资产费用' },
        { id: 2, name: '管理费用' }
      ],
      objectList: [],
      paymentInfoModel: {
        contractId: 0,
        propertyAmount: 15,
        depositAmount: 0,
        paymentEndDate: '',
        paymentStartDate: '',
        paymentDueDate: '',
        paymentCycle: 1,
        paymentAdvanceDay: 5
      },
      rules: {
        feetype: [{ required: true, message: '请选择费用类型' }],
        type: [{ required: true, message: '请选择区间类型' }],
        freeStartDate: [
          { required: true, message: '请选择不计费区间开始日期' }
        ],
        freeEndDate: [{ required: true, message: '请选择不计费区间结束日期' }]
      },
      rules1: {
        type: [{ required: true, message: '请选择区间类型' }],
        duration: [{ required: true, message: '请输入不计费周期' }]
      },
      paymentInfoRules: {
        depositAmount: [{ required: true, message: '请输入履约保证金' }],
        propertyAmount: [{ required: true, message: '请输入运营管理费' }],
        paymentCycle: [{ required: true, message: '请输入结算周期' }],
        paymentAdvanceDay: [{ required: true, message: '请输入提前付款天数' }]
      },
      coIdArray: [],
      freePeriodFormModel: {
        contractId: 0,
        type: 1,
        coIds: '',
        feeType: 1,
        freeStartDate: '',
        freeEndDate: '',
        duration: undefined
      }
    }
  },
  mounted () {},
  created () {
    this.initFreePeriodList()
    this.initObjectList()
  },
  methods: {
    formatMoney (number) {
      return toMoney(number)
    },
    formatUpperMoney (money) {
      return parseUpperMoney(money)
    },

    initObjectList () {
      listObjectAndFee({ contractId: this.contractInfo.id }).then((res) => {
        this.objectList = res
      })
    },
    initFreePeriodList () {
      listFreePeriod({ contractId: this.contractInfo.id }).then((res) => {
        this.freePeriodArray = res
      })
    },
    handldSave () {
      this.$refs.freePeriodForm.validate((valid) => {
        if (valid) {
          this.freePeriodFormModel.contractId = this.contractInfo.id

          this.freePeriodFormModel.freeStartDate = ParseDate(
            this.freePeriodFormModel.freeStartDate
          )
          this.freePeriodFormModel.freeEndDate = ParseDate(
            this.freePeriodFormModel.freeEndDate
          )

          this.freePeriodFormModel.coIds =
            this.coIdArray.toString()

          addFreePeriod(this.freePeriodFormModel).then((res) => {
            this.visible = false
            this.initFreePeriodList()
          })
        } else {
          this.$Notice.error({ desc: '请正确输入信息' })
        }
      })
    },
    deletefreePeriod (fId) {
      this.$Modal.confirm({
        title: '删除确认',
        content: '是否删除当前免租期？',
        onOk: () => {
          deleteFreePeriod({ freeId: fId }).then((res) => {
            this.initFreePeriodList()
          })
        }
      })
    },
    showPaymentInfoModal () {
      this.paymentInfoModel.depositAmount = this.contractInfo.depositAmount
      this.paymentInfoModel.propertyAmount = this.contractInfo.propertyAmount

      this.paymentInfoModel.paymentCycle = this.contractInfo.paymentCycle
      this.paymentInfoModel.paymentAdvanceDay = this.contractInfo.paymentAdvanceDay
      this.paymentInfoModel.paymentStartDate = this.contractInfo.paymentStartDate
      this.paymentInfoModel.paymentEndDate = this.contractInfo.paymentEndDate
      this.paymentInfoModel.paymentDueDate = this.contractInfo.paymentDueDate
      this.editpaymentInfoModal = true
    },
    handldPaymentInfoSave () {
      this.$refs.paymentInfoForm.validate((valid) => {
        if (valid) {
          // this.$Modal.confirm({
          //   title: '操作提示',
          //   content: '请确认合同基本信息是否填写完整？',
          //   onOk: () => {

          //   }
          // })
          savePayAmount({
            contractId: this.contractInfo.id,
            depositAmount: this.paymentInfoModel.depositAmount,
            propertyAmount: this.paymentInfoModel.propertyAmount
          }).then((res) => {
            if (res) {
              savePayCycle({
                contractId: this.contractInfo.id,
                paymentCycle: this.paymentInfoModel.paymentCycle,
                paymentAdvanceDay: this.paymentInfoModel.paymentAdvanceDay,
                paymentStartDate: this.paymentInfoModel.paymentStartDate
                  ? ParseDate(this.paymentInfoModel.paymentStartDate)
                  : '',
                paymentEndDate: this.paymentInfoModel.paymentEndDate
                  ? ParseDate(this.paymentInfoModel.paymentEndDate)
                  : '',
                paymentDueDate: this.paymentInfoModel.paymentDueDate
                  ? ParseDate(this.paymentInfoModel.paymentDueDate)
                  : ''
              }).then((result) => {
                if (result) {
                  this.editpaymentInfoModal = false
                  this.$store.commit('set_contract_update', new Date())
                }
              })
            }
          })
        } else {
          this.$Notice.error({ desc: '请正确输入信息' })
        }
      })
    },

    handlePreviousStep () {
      this.$store.commit('set_contract_currentStep', 'price')
      this.$store.commit('set_contract_update', new Date())
    },
    handleNextStep () {
      this.$store.commit('set_contract_currentStep', 'files')
      this.$store.commit('set_contract_update', new Date())
    }
  },
  watch: {
    beginUpdate () {}
  },
  computed: {
    contractInfo () {
      return this.$store.state.investmentContract.contractInfo
    },
    beginUpdate () {
      return this.$store.state.investmentContract.beginUpdate
    }
  }
}
</script>
